@import "./variables";
@import "./calendar";

.input-content {
    margin-bottom: 2em;
    position: relative;

    .label {
        background-color: $white;
        padding: 0;
        margin: 10px 0;
        font-size: $font-size-label;
        font-weight: 500;
        color: $black;
    }

    .label.floating {
        font-family: $font-family !important;
        top: -0.5em;
        left: 2em;
        padding: 0 0.5em;
        z-index: 1;
    }

    .input {
        width: 100%;
    }

    .input > input {
        border-radius: 0;
        font-size: $font-size;
        height: 3.5em;
    }

    ::placeholder {
        color: $color-gray;
    }

    &.filled {
        .input > input {
            border-color: $color-dark;
        }
    }

    .input > input:hover {
        border-color: $color-gray;
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .input > input:focus {
        border-color: $color-gray;
    }

    .calendar-container > div > div > .rc-calendar-picker {
        width: 25em;
    }

    .input-error {
        color: $red;
        margin-top: 0.25em;
    }

    .file {
        cursor: pointer;
    }

    span {
        .ui.input input {
            cursor: pointer !important;
        }
    }
}

.input-password-container {
    margin-bottom: $padding;

    .input-password-content {
        position: relative;
        display: flex;
        border-radius: 0;
        border: 1px solid rgba(34, 36, 38, 0.15);

        .label {
            background-color: $white;
            padding: 0;
            margin: 10px 0;
            font-size: $font-size-label;
            font-weight: 500;
            color: $black;
        }

        .label.floating {
            top: -0.5em;
            left: 2em;
            padding: 0 0.5em;
            z-index: 1;
        }

        .input {
            width: 90%;
        }

        ::placeholder {
            color: $black !important;
            opacity: 0.5;
        }

        .ui.button {
            width: 5%;
            background-color: transparent;
            color: $color-dark;
            border-top-left-radius: 0;
            border-bottom-left-radius: 0;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
        }

        .input > input {
            font-size: $font-size;
            padding: $padding;
            height: 3em;
            border: none;
            border-radius: 0;
            border-top-left-radius: 8px;
            border-bottom-left-radius: 8px;
            border-top-right-radius: 0;
            border-bottom-right-radius: 0;
        }
    }

    .input-error {
        color: $red;
        margin-top: 0.25em;
    }

    .input-password-content.input-error {
        border-color: #e0b4b4;

        .ui.button {
            color: #e0b4b4;
        }
    }

    .input-password-content:hover {
        border-color: rgba(34, 36, 38, 0.35);
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .input-password-content:focus {
        border-color: rgba(34, 36, 38, 0.35);
    }
}

.input-content.bordered > .StripeElement {
    border: 1px solid rgba(34, 36, 38, 0.15);
    padding: $padding;
    border-radius: 0;
}

.input-content.bordered.input-error > .StripeElement {
    border-color: #e0b4b4;
    background-color: rgba(224, 180, 180, 0.1);
    color: rgba(224, 180, 180, 0.1);
}

.input-content.bordered > .StripeElement:focus {
    border-color: rgba(34, 36, 38, 0.35);
}

.input-content.bordered > .StripeElement:hover {
    border-color: rgba(34, 36, 38, 0.35);
}

.checkbox-content {
    margin-bottom: 1em;

    .ui.checkbox.checked {
        input {
            color: $color-cards;
        }

        label::after,
        .label::before {
            background-color: $color-cards;
            color: $white !important;
        }
    }

    &.no-margin {
        margin: 0 1em;
    }

    &.inverted {
        .ui.checkbox.checked > label::after {
            background-color: $white;
            color: $color-green-dark;
        }

        label {
            color: $white !important;
        }

        .ui.checkbox > label::before {
            border: 1px solid $white;
            background-color: transparent;
        }
    }

    &.bigSize {
        .ui.checkbox > label {
            font-size: $font-size-label;
            text-decoration: underline;
        }
    }
}

.radio-content {
    margin-bottom: 1.5em;
    align-items: center;
    display: flex;
    background-color: transparent;

    label {
        background-color: transparent;
        font-size: 1.4em;
        font-weight: 400;
        color: $black;
        cursor: pointer;

        .ui.image {
            width: 120px;
            cursor: pointer;
        }
    }

    label.smallSize {
        font-size: 1.2em;
    }

    input[type="radio"] {
        border: 1px solid $color-green-dark;
        border-radius: 10px;
        cursor: pointer;
        display: inline-block;
        height: 1.2em;
        margin-right: 1em;
        position: relative;
        width: 1.2em;
        -webkit-appearance: none;
    }

    input[type="radio"]:focus {
        outline: none;
    }

    input[type="radio"]:after {
        border-radius: 25px;
        content: "";
        display: block;
        height: 7px;
        left: 4px;
        position: relative;
        top: 4px;
        width: 7px;
    }

    input[type="radio"]:checked:after {
        background-color: $color-green-dark;
    }
}

.input-button-content {
    margin-bottom: 1.5em;

    &.filled {
        .input-section > .ui.input > input {
            border-color: $color-dark;
            border-right-color: transparent;
        }
    }

    .input-section {
        display: flex;

        .ui.input {
            width: 75%;

            input {
                border-top-left-radius: 8px;
                border-bottom-left-radius: 8px;
                border-top-right-radius: 0;
                border-bottom-right-radius: 0;
                font-size: $font-size;
                width: 85%;
                border-radius: 0;
                height: 3.5em;
                border-color: $color-gray;
            }
        }

        .ui.button {
            border-radius: 0;
            width: 25%;
            background-color: $color-light-gray;
            color: white;
            border-radius: 0;
            margin: 0;
            padding: 0;
        }
    }
}

.text-area-content {
    margin-bottom: 2em;
    position: relative;

    .label {
        background-color: $white;
        padding: 0;
        margin: 10px 0;
        font-size: $font-size-label;
        font-weight: 500;
    }

    .label.floating {
        font-family: $font-family !important;
        top: -0.5em;
        left: 2em;
        padding: 0 0.5em;
        z-index: 1;
    }

    .textarea {
        border: 1px solid rgba(34, 36, 38, 0.15);
        width: 100%;
        border-radius: 0;
        padding-bottom: 1.5em;
        padding: 1em 0.5em;
    }

    .textarea > textarea {
        width: 100%;
        padding: 5px 10px;
        font-size: $font-size;
        outline: none;
        resize: none;
        border: none;
        border-radius: 0;
    }

    .textarea:hover {
        border-color: rgba(34, 36, 38, 0.35);
        -webkit-box-shadow: none;
        box-shadow: none;
    }

    .textarea:focus {
        border-color: rgba(34, 36, 38, 0.35);
    }

    .input-error {
        color: $red;
        margin-top: 0.25em;
    }

    .counter {
        position: absolute;
        right: 1em;
        bottom: 0.5em;
        color: $color-light-gray;
    }
}

.input-pictures-content {
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;

    .ui.label {
        cursor: pointer;
        border: 1px dashed $color-gray;
        background-color: $color-extra-light-gray;
        border-radius: 0;
        width: 80%;

        &:hover {
            background-color: $color-light-gray;
            box-shadow: 3px 3px 5px 0px $color-light-gray;

            p {
                color: $color-dark;
            }
        }

        label {
            display: flex;
            justify-content: space-between;
            align-items: center;
            cursor: pointer;

            .ui.image {
                width: 20%;
                cursor: pointer;
            }

            p {
                width: 80%;
                cursor: pointer;
                margin: 0;
                text-align: center;
                color: $color-gray;
                font-size: 1.5em;
            }
        }
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .calendar-container > div > div > .rc-calendar-picker {
        width: 92.4vw !important;
    }

    .input-password-container {
        .input-password-content {
            .ui.input {
                width: 86%;
            }
        }
    }

    .input-pictures-content {
        .ui.label {
            width: 90%;
            outline: none;

            label {
                p {
                    font-size: 1.2em;
                }
            }
        }
    }
}

@media only screen and (max-width: 1024px) and (orientation: landscape) {
    .calendar-container > div > div > .rc-calendar-picker {
        width: 92.4vw !important;
    }

    .input-password-container {
        .input-password-content {
            .ui.input {
                width: 86%;
            }
        }
    }
}
