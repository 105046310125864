@import "./variables";

.ui.cards > .ui.card > .card-overlay {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .suggested-label {
        left: calc(-0rem - 1.2em) !important;
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: $orange;
        background-image: linear-gradient(315deg, $orange 0%, #ffdd00 64%, $orange 100%);
        color: $color-dark;
    }
}

.ui.card > .card-overlay {
    height: 100%;
    width: 100%;
    background-color: transparent;
    position: absolute;
    z-index: 1;
    cursor: pointer;

    .suggested-label {
        animation-duration: 5s;
        animation-fill-mode: both;
        animation-iteration-count: infinite;
        animation-name: placeHolderShimmer;
        animation-timing-function: linear;
        background-color: $orange;
        background-image: linear-gradient(315deg, $orange 0%, #ffdd00 64%, $orange 100%);
        color: $color-dark;
    }
}

.ui.cards .ui.card.plan-card {
    z-index: 0;
    border-radius: 0px;
    width: 10em;
    cursor: pointer;
    border: 1px solid $color-cards;
    box-shadow: none;
    border-radius: 4px;
    height: 72px;

    .content {
        padding: 1em 0.5em;
    }

    .card-content {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .card-image-section {
        img {
            height: 120px;
        }
    }

    .card-data-section {
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin: 0px;

        .ui.header {
            font-size: 1em;
            margin-bottom: 0.5em;
        }

        .description {
            text-align: center;
            color: $color-green-dark;
        }

        .meta {
            color: $color-dark;
        }
    }

    &.selected-plan,
    &:hover {
        background-color: $color-cards;
        border: 1px solid $color-cards;

        .card-data-section {
            .ui.header,
            .meta {
                color: $white;
            }
        }
    }
    //adding a background color to the first card
    // &:first-child {
    //     background-color: $color-cards !important;
    //     border: 1px solid $color-cards;
    // }
}

.ui.card.plan-card.suggested-box {
    border: 1px solid $orange;
}

.ui.cards .ui.card.order-card {
    background-color: transparent;
    border: none;
    box-shadow: none;
    padding: 0;
    margin: 0;

    .content {
        padding: 0 0.8em;

        .order-card-header {
            display: flex;
            align-items: center;
            position: relative;

            .ui.header {
                margin: 0.5em 0.2em;
                font-size: 1.4em;
            }

            .remove-button {
                border: none;
                color: $color-gray;
                background-color: transparent;
                font-size: 1.4em;
                padding: 0 0.5em 0 0;
                cursor: pointer;
                outline: none;
            }

            .ui.button {
                background-color: transparent;
                border: 1px solid $color-green-dark;
                color: $color-green-dark;
                width: 2.5em;
                height: 2.5em;
                padding: 0;
            }

            span {
                position: absolute;
                right: 0;
                font-size: 1.5em;
            }
        }

        .description {
            font-size: 1.2em;
        }

        .meta {
            font-size: 1.2em;
        }
    }

    &:last-child {
        margin-bottom: 1em;
    }
}

.ui.cards > .ui.card.month-box-card {
    border-radius: 0px;
    position: relative;
    max-width: 32em;
    height: 7.5em;

    &:hover {
        box-shadow: 1px 1px 1px 1px $color-light-gray;
    }

    .indicator {
        width: 0.8em;
        height: 0.8em;
        background-color: #d2142d;
        position: absolute;
        z-index: 1;
        right: 0.8em;
        top: 0.8em;
        border-radius: 50%;
    }

    .content {
        padding: 0;
        .month-box-card-container {
            display: flex;
            justify-content: flex-start;
            padding: 0;
            height: 100%;
            width: 100%;

            .image {
                width: 30%;
                height: 7.4em;

                img {
                    width: 100%;
                    max-height: 100%;
                }

                &.upgrade {
                    width: 25%;
                    height: 100%;
                    text-align: center;

                    img {
                        width: 60%;
                    }
                }
            }

            .info {
                width: 70%;
                height: 100%;
                padding: $padding;
                display: flex;
                flex-direction: column;
                justify-content: center;

                .header-div {
                    .fake-button {
                        background: transparent;
                        border: none;
                        outline: none;
                        text-decoration: underline;
                        font-size: 1.125em;
                    }
                }

                h3 {
                    margin-bottom: 0.2em;
                }

                .meta {
                    font-size: 1.1em;
                }

                .description {
                    text-decoration: none;
                    font-size: 1.2em;
                }

                &.upgrade {
                    width: 75%;
                }
            }
        }
    }
}

.ui.card.settings-card {
    border-radius: 0px;

    .content.header-container {
        display: flex;
        justify-content: space-between;
        border-bottom: none;
        margin-top: 1em;

        .edit-button {
            text-decoration: underline;
            font-size: 1.286em;
        }

        .header {
            width: 95%;
            font-family: $font-family !important;
            font-size: 1.715em;
            font-weight: 700;
        }

        i {
            width: 5%;
            color: $color-light-gray;
        }
    }

    .content {
        border-top: none;

        .info-container {
            display: flex;
            align-items: center;

            p {
                margin-bottom: 0.5em;
                font-size: 1.286em;
            }

            .popup-button {
                box-shadow: none !important;
                color: $color-cards !important;
                width: 2em;
            }

            .info-title {
                width: 40%;
            }

            .info-data {
                color: $color-gray;
                width: 60%;
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
            }

            .info-data.link {
                color: $black;
                text-decoration: none;
                cursor: pointer;
            }

            .info-data.link.red-color {
                color: $red;
                text-decoration: underline;
            }

            .info-data.link.green-color {
                color: $color-green-dark;
                text-decoration: underline;
            }
        }
    }

    &.cards-together {
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;
        margin-bottom: 0;
    }

    &.benefits {
        padding: 2.5em 1em;
        border-top-left-radius: 0;
        border-top-right-radius: 0;
        margin-top: 0;

        .header {
            font-size: 1.2em;
        }
    }
}

@keyframes placeHolderShimmer {
    0% {
        background-position: -600px 0;
    }
    100% {
        background-position: 600px 0;
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .ui.cards .ui.card.plan-card {
        width: 95%;

        .card-content {
            flex-direction: row;
        }
    }

    .ui.cards {
        .ui.card.order-card {
            .content {
                .order-card-header {
                    .ui.header {
                        font-size: 1.25em;
                    }
                    span {
                        font-size: 1.25em;
                    }
                }
            }
        }

        .ui.card.month-box-card {
            &.upgrade {
                height: auto;
            }

            .month-box-card-container {
                .info {
                    margin-left: 10px;
                }
            }
        }
    }
}

.donate-box-card {
    flex: 0 0 auto;
    margin: 0 1em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 5px;
    height: auto;
    width: 18em;

    .image-container {
        height: 12em;
        width: 100%;
        background-color: #eee;

        .image {
            height: 100%;
            width: 100%;
        }
    }

    .box-name {
        text-align: left;
        font-size: 1.15em;
        color: $color-light-gray;
        text-transform: uppercase;
        width: 100%;
        padding: 5px 0;
        margin-top: 0;
    }
}

.donate-box-card:first-child {
    margin-left: 0;
}

@media only screen and (max-width: 360px) and (orientation: portrait) {
    .ui.cards {
        .ui.card.order-card {
            .content {
                .order-card-header {
                    .ui.header {
                        font-size: 1.1em;
                    }
                    span {
                        font-size: 1.2em;
                    }
                }
            }
        }
    }
}

.card-color {
    color: $white;
}

.fake-button {
    background: transparent;
    border: none;
    outline: none;
    text-decoration: underline;
    font-size: 1.125em;
}
