@import "./variables";

.message-container {
    text-align: center;
    margin-bottom: 1em;
    //padding: 0 20em;

    .ui.message {
        border-radius: 5px;
        border: none;
        background-color: rgba(0, 0, 0, 0.7);
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        p {
            color: $white;
            margin: 0;
        }

        button {
            color: $white;
            background-color: transparent;
            border: none;
            outline: none;
            font-size: 1.2em;
            cursor: pointer;
            font-weight: bold;
        }
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .message-container {
        padding: 0;

        .ui.message {
            justify-content: space-between;
        }
    }
}
