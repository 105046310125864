@import "./src/scss/components/core";
@import "./src/scss/components/variables";
@import "./src/scss/components/utilities";
@import "./src/scss/components/layout";

.manage-subscription-container {
    .box-sections {
        margin: 1em 0 2em 0;

        .section-title {
            color: $color-cta;
            text-transform: capitalize;
            padding: 0.25em 0;
            border-radius: 10px;
            font-size: 1.5em;
            font-family: "Nunito Sans Bold" !important;
        }
    }

    .cropper-section-container {
        position: relative;

        .cropper-controls {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            padding: 0.5em;

            .crop-picture {
                background-color: $color-green-dark;
                color: $white;
            }

            .restore-picture {
                background-color: $color-cta;
                color: $white;
            }
        }
    }

    .select-subscription {
        padding: $padding;
        background: $color-extra-light-gray;
        border-radius: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .choose-text-content {
            display: flex;
            align-items: center;
            color: $color-dark;
            font-size: 1.429em;

            p {
                margin: 0;
            }
        }

        .dropdown-content {
            width: 60%;
        }
    }

    .next-payment {
        text-align: center;
        color: $color-light-gray;
    }

    .remaining-boxes-content {
        padding: 1em 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        height: auto;
    }

    .recoveringCard {
        border: 1px solid #eee;
        margin: $margin 0;
        border-radius: 10px;

        .title {
            border-bottom: 1px solid #eee;
            padding: $padding;
        }

        .text {
            padding: $padding;
        }

        .radio-actions {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .action {
            padding: $padding 14em;
        }
    }

    .ui.grid.user-information-grid {
        .ui.list {
            .item {
                padding: 10px 0;
                .header {
                    margin-bottom: 10px;
                }

                .special-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    div > .card-info {
                        display: flex;
                        align-items: center;
                        margin: 0;
                    }

                    .ui.button {
                        width: 100px;
                        background-color: $white;
                        border: none;
                        text-decoration: underline;
                        font-size: 1.2em;
                        font-weight: 500;

                        &:hover {
                            font-weight: bold;
                        }
                    }
                }

                &.info-item {
                    display: flex;
                    font-size: 18px;
                    margin: 0 0 0 0.5em;
                    justify-content: flex-start;
                    align-items: center;
                    word-break: normal;

                    .list-element {
                        display: flex;
                        font-size: 18px;
                        margin: 0 0 0 0.5em;
                        word-break: break-all;
                        font-family: $font-family;
                        width: 55%;
                        justify-content: space-between;

                        &.bold {
                            font-weight: 700;
                        }
                    }

                    .header {
                        margin: 0;
                        width: 45%;
                    }

                    .ui.button {
                        width: 100px;
                        background-color: $white;
                        color: $black;
                        border: none;
                        text-decoration: underline;
                        font-size: 1em;
                        font-weight: 500;
                        padding: 0;

                        &:hover {
                            font-weight: bold;
                        }
                    }
                }
            }
        }
    }
}

.box-image-section {
    position: relative;
    margin-bottom: 10px;

    .upgrade-images {
        width: 100%;
        display: flex;
        align-items: center;

        .ui.image {
            max-height: 100px;
            width: auto;
        }
    }
}

.ui.container > .tabs-container > div {
    .tab {
        border: none;
    }
}

.container-padding-feature.feature-section {
    .input-pdf-container {
        width: 100%;
        min-height: 40vh;
        border-radius: 0;

        .custom-pdf-viewer {
            overflow: auto;
            border: 1px solid $color-gray;

            div {
                canvas {
                    box-shadow: 2.5px 2.5px 5px 0px rgba(0, 0, 0, 0.5);
                    margin: 0;
                }
            }
        }
    }

    .pdf-file-name {
        text-align: center;
        font-size: 0.8em;
    }

    .box-sections {
        margin: 1em 0 2em 0;

        .section-title {
            color: $color-cta;
            text-transform: capitalize;
            padding: 0.25em 0;
            font-size: 1.2em;
            border-radius: 10px;
        }
    }

    .cropper-section-container {
        position: relative;

        .cropper-controls {
            position: absolute;
            bottom: 0;
            display: flex;
            justify-content: space-evenly;
            width: 100%;
            padding: 0.5em;

            .crop-picture {
                background-color: $color-green-dark;
                color: $white;
            }

            .restore-picture {
                background-color: $color-cta;
                color: $white;
            }
        }
    }

    .select-subscription {
        padding: $padding;
        background: $color-extra-light-gray;
        border-radius: 0px;
        display: flex;
        justify-content: flex-start;
        align-items: center;

        .choose-text-content {
            display: flex;
            align-items: center;

            p {
                margin: 0;
            }
        }

        .dropdown-content {
            width: 60%;
        }
    }

    .next-payment {
        text-align: center;
        color: $color-light-gray;
    }

    .add-photos-title {
        text-align: center;
        font-size: 1.2em;
        margin-top: 0.5em;
        margin-bottom: 2.5em;
    }

    .photos-quantity {
        color: $color-gray;
    }

    .image-preview-container {
        padding: 1em 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        margin: 1em 0;
        align-items: center;

        .image-preview {
            position: relative;
            flex: 0 0 auto;
            margin: 0 1em;
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 5px;
            height: fit-content;
            width: 120px;

            .ui.image {
                max-height: 100%;
            }
        }

        .image-preview.big-images {
            width: 280px;

            .ui.image {
                width: 100%;
            }
        }
    }

    .remaining-boxes-content {
        padding: 1em 0;
        display: flex;
        flex-wrap: nowrap;
        overflow: auto;
        height: auto;
    }

    .recoveringCard {
        border: 1px solid #eee;
        margin: $margin 0;
        border-radius: 10px;

        .title {
            border-bottom: 1px solid #eee;
            padding: $padding;
        }

        .text {
            padding: $padding;
        }

        .radio-actions {
            display: flex;
            justify-content: space-evenly;
            align-items: center;
        }

        .action {
            padding: $padding 14em;
        }
    }

    .ui.grid.user-information-grid {
        .ui.list {
            .item {
                padding: 10px 0;
                .header {
                    margin-bottom: 10px;
                }

                .special-content {
                    display: flex;
                    align-items: center;
                    justify-content: space-between;

                    .ui.button {
                        width: 100px;
                        color: $color-cta;
                        border: 1px solid $color-cta;
                        background-color: $white;

                        &:hover {
                            background-color: $color-cta;
                            color: $white;
                        }
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .manage-subscription-container {
        padding: 0;

        .select-subscription {
            flex-direction: column;
        }

        .recoveringCard {
            .action {
                padding: $padding 3em;
            }
        }
    }

    .ui.tabular.menu {
        .item {
            font-size: 1em !important;
            width: 180px !important;
        }
    }

    .ui.grid.subscription-grid {
        margin-top: -1rem;
        margin-bottom: -1rem;
        margin-left: auto;
        margin-right: auto;

        .column {
            padding: 0;
        }
    }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
    .manage-subscription-container {
        .input-pdf-container {
            height: 25vh;
        }
    }
}

.tabs-container {
    padding-top: 3em;
}

.ui.tabular.menu .item {
    font-family: $subtitle-font-family-regular !important;
    font-size: 20px;
    font-weight: 600;
}

h3.ui.header {
    font-family: $font-family !important;
    padding-bottom: 10px;
    font-weight: 700;
}

.subtitle-bigger {
    font-size: 1.715em !important;
}

.ui.card.settings-card.benefits {
    font: 1.286em $font-family !important;
    padding: 1em;
}

.ui.card.settings-card.benefits .header {
    font-family: $font-family !important;
    font-weight: 700;
    font-size: 1.111em;
}

.ui.bulleted.list > .item {
    padding-left: 1em;
    padding-top: 0.8em;
    padding-bottom: 1em;
}

.ui.card .meta,
.ui.cards > .card .meta {
    font-family: $font-family !important;
    font-size: 1.143em !important;
    font-weight: 300;
}

.ui.cards > .ui.card.month-box-card .month-box-card-container .info .description {
    font-size: 1.285em;
    text-decoration: none;
    color: $color-cards !important;
}

.eighteen-px-size {
    font-size: 1.286em;
}

.header-div {
    display: flex;
    justify-content: space-between;
}

.data-labels {
    font-family: $font-family;
    font-weight: 700;
    font-size: 1.285em;
}

.ui.tabular.menu .item:not(.active):hover {
    text-shadow: 1px 1px 1px $color-gray;
}

.ui.tabular.menu .item:not(.active) {
    background-color: $color-extra-light-gray;
}

.ui.list {
    margin: 0 !important;
}

.image-credit-card {
    padding-right: 15px;
    width: 60px !important;
    height: 50px !important;
}

.ui.segment,
.ui.segments .segment {
    box-shadow: none;
}
.segment-element {
    cursor: pointer;
    &:hover {
        box-shadow: -4px 4px 3px 0 $color-light-gray !important;
    }
}

.expiration {
    padding-left: 60px;
}

.cancel-buttons-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;

    .separator {
        font-size: 1.2em;
        font-weight: bold;
    }
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .cancel-buttons-container {
        flex-direction: column;
    }
}
