@import './variables';

/* Box sizing rules */
*,
*::before,
*::after {
	box-sizing: border-box;
}

html {
	font-family: $font-family;
	line-height: $font-line-height;
	-webkit-text-size-adjust: 100%;
}

/* Remove default padding */
ul[class],
ol[class] {
	list-style: none;
	padding: 0;
}

/* Remove default margin */
body,
h1,
h2,
h3,
h4,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
	margin: 0;
}

/* Set core body defaults */
body {
	min-height: 100vh;
	scroll-behavior: smooth;
	text-rendering: optimizeSpeed;
	line-height: 1.5;
	display: block;
}

/* Remove list styles on ul, ol elements with a class attribute */
ol,
ul {
	list-style: none;
	margin: 0;
	padding: 0;
}

/* A elements that don't have a class get default styles */
a:not([class]) {
	text-decoration-skip-ink: auto;
}

/* Make images easier to work with */
img {
	max-width: 100%;
}

/* Natural flow and rhythm in articles by default */
article > * + * {
	margin-top: 1em;
}

/* Inherit fonts for inputs and buttons */
input,
button,
textarea,
select {
	font: inherit;
}

section,
footer {
	display: flow-root;
}

/* Remove all animations and transitions for people that prefer not to see them */
@media (prefers-reduced-motion: reduce) {
	* {
		animation-duration: 0.01ms !important;
		animation-iteration-count: 1 !important;
		transition-duration: 0.01ms !important;
		scroll-behavior: auto !important;
	}
}

h1,
h2,
h3,
h4 {
	line-height: $font-line-height;
	margin: $margin 0;
	font-weight: $font-weight-medium;
	display: block;
}

h1 {
	font-size: 2em;
}
h2 {
	font-size: 1.5em;
}
h3 {
	font-size: 1.2em;
}
h4 {
	font-size: 1.1em;
}

p {
	margin: $margin-large 0;
}

ul.a {
	list-style-type: square;
	color: $white;
}

a {
	text-decoration: none;
	&:link {
		color: $color-cta;
	}
	&:visited {
		color: $color-cta;
	}
	&:hover,
	&:focus {
		color: $black;
		text-decoration: underline;
	}
	&.btn {
		text-decoration: none;
		color: $white;
		background-color: $color-cta;
		border-radius: $border-radius;
		font-weight: $font-weight-medium;
		text-align: center;
		display: inline-block;
		border: 0.063em solid $color-cta-light;
		padding: 10px 80px;
		align-items: center;
		border-radius: 10px;

		&.center {
			margin: $margin auto;
		}

		&:hover,
		&:focus {
			text-decoration: none;
			background-color: $color-cta-light;
		}
	}

	&.white {
		color: $white;
		text-decoration: underline;

		&:hover,
		&:focus {
			color: $color-cta-light;
		}
	}
}

@media screen and (min-width: #{$grid-medium-breakpoint}) {
	a {
		&.btn {
			padding: 10px 30px !important;
			&.center {
				margin: $margin-large auto;
			}
		}
	}
}

blockquote {
	font-style: italic;
}
