@import "./variables";
@import "./utilities";

.card-overlay-upgrade {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 2;
    cursor: pointer;
}

.improve-indicator {
    margin: auto;
    width: 100%;
    height: 100%;
    padding: 10px;
    position: relative;
    top: 0;
    border-radius: 10px;
    text-align: center;
    color: $color-lght-gray-box;
    font-size: $font-size;
    z-index: 1;
    &:hover {
        color: $color-dark;
        box-shadow: 0px 0px 10px 0px $orange;
        font-weight: bold;
    }
}

.ui.cards {
    .card {
        .content {
            padding: 1em;
        }
    }
}

.card-container {
    margin-top: 0;

    &.upgrade {
        height: 16.5em;
    }

    .bd-text {
        max-width: 250px;
        margin: auto 4em;
        text-align: center;
    }

    .ui.card.boxes-card.disabled-box {
        .box-image-section {
            img {
                opacity: 0.2;
            }
        }
    }

    .ui.card.boxes-card {
        width: 16em;
        height: 14em;
        border-radius: 0;
        border: 1px solid rgba(185, 139, 85, 0.4);
        box-shadow: none;
        margin: 0.5em;
        cursor: pointer;
        &.selected {
            background-color: $color-cards;
            .flex-container {
                .date-text {
                    .text-h1,
                    .card-subtitle-one,
                    .closed-date,
                    .meta {
                        color: $white;
                    }
                }
            }
        }
        &:hover {
            border: 1px solid $color-cards;
            box-shadow: none;
        }

        .flex-container {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;

            .date-text {
                margin: 0;

                .text-h1 {
                    font-size: 1.5em !important;
                }
                .card-subtitle-one,
                .meta {
                    margin: 0;
                    padding: 0;
                    font-size: 0.8em !important;
                }
                .empty-style {
                    font-size: 1em !important;
                }
                .closed-date {
                    font-size: 1em;
                    font-family: $font-family !important;
                }
            }
        }

        .card-image-event {
            display: flex;
            align-items: center;
            justify-content: space-around;
            padding: 2px;
            width: 100%;
            position: absolute;
            bottom: 5%;

            .ui.image {
                width: 30%;
            }

            .description {
                text-align: left;
                max-width: 50%;
                line-height: 15px;
            }
        }

        .box-image-section {
            height: 10em;

            img {
                height: 12em;
                width: auto;
            }
        }

        .image-logo {
            max-height: 40px;
            object-fit: contain;
        }

        .upgrade-offer {
            display: flex;
            align-items: center;
            text-align: center;
            position: absolute;
            line-height: 1;
            padding-top: 5px;
            top: -32px;
            left: -32px;
            border-radius: 50% !important;
            border: none;
            width: 65px;
            height: 65px;
            background-color: $color-green-dark;
            color: $white;
            z-index: 1;
        }

        .date-text {
            width: 100%;
            margin: 0.4em 0;
            text-align: left;
            min-height: 4em;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: flex-start;
            font-size: 1em;
            color: $black;
            padding-bottom: 5px;

            .link {
                color: $red;
                text-decoration: underline;
                cursor: pointer;
            }

            .charge-info {
                font-size: 0.8em;
                color: $color-light-gray;
            }

            .closed-date {
                color: $color-cards;
                opacity: 0.5;
                animation: pulsate 1s ease-out;
                animation-iteration-count: infinite;
                font-family: $font-family;
                font-size: 1.143em;
                padding-bottom: 5px;
            }

            @keyframes pulsate {
                0% {
                    opacity: 0;
                }
                50% {
                    opacity: 1;
                }
                100% {
                    opacity: 0;
                }
            }
        }
    }
}

.empty-style {
    color: $color-cards;
    padding-bottom: 5px;
    font-family: $font-family !important;
    font-weight: 300;
    &.white {
        color: $white !important;
    }
}

.span-price {
    padding: 0.3em 0.5em 0.2em 0.5em;
    background-color: $white;
    color: $color-cards;
    &.selected {
        background-color: $color-cards !important;
        color: $white !important;
    }
}

.card-title {
    font-size: 1.72em !important;
}
.card-subtitle-one {
    font-family: $font-family;
    font-size: 1em;
    font-weight: 700;
    padding-bottom: 5px;
}
.card-subtitle-two {
    font-size: $font-family;
    font-size: 1.143em;
    padding-bottom: 5px;
}

.normal-text {
    font-family: $font-family !important;
    font-size: 1em;
    color: $color-cards;
    font-weight: 700;
}

.second-normal {
    padding-bottom: 5px !important;
}

.inner-content {
    position: relative;
    top: -7px;
    padding-left: 1em;
    padding-right: 1em;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .card-container {
        margin-top: 0;
        width: 100%;
        display: flex;
        justify-content: center;

        .ui.card.boxes-card {
            width: 70%;
        }
    }
}
