@import "./variables";

.ui.grid {
    margin: 0;
}

.container-padding {
    padding: 0 28%;
}

.container-padding-feature {
    padding: 0 26.5%;
}

.container-padding-small {
    padding: 0 5%;
}

.button-container-padding {
    padding: 0 30% 2em 30%;
}

.container-padding.show-computer {
    padding: 0;
}

.container-padding.show-mobile {
    padding: 0;
}

.container-padding.padding-bottom {
    padding-bottom: $padding-bottom;
}

.manage-subscription-container {
    padding: 0 2em 0 4em;
    &.lesser {
        padding: 0;
    }
}

@media only screen and (max-width: 768px) {
    .container-padding,
    .container-padding-feature {
        padding: 0;
    }

    .container-padding-small {
        padding: 0;
    }

    .manage-subscription-container {
        padding: 0;
    }

    .button-container-padding {
        padding: 0 $padding $padding $padding;
    }

    .tab-subscription-container {
        padding-left: 1em;
    }
}

@media only screen and (width: 768px) and (height: 1024px) {
    .container-padding,
    .container-padding-feature {
        padding: 0;
    }

    .button-container-padding {
        padding: 0;
    }

    .manage-subscription-container {
        padding: 0;
    }
}

@media only screen and (min-width: 1024px) and (min-height: 768px) {
    .ui.grid.grid.grid > .row > [class*="large screen only"].column:not(.mobile),
    .ui.grid.grid.grid > [class*="large screen only"].column:not(.mobile),
    .ui.grid.grid.grid > [class*="large screen only"].row:not(.mobile),
    .ui[class*="large screen only"].grid.grid.grid:not(.mobile) {
        display: block !important;
    }
}
