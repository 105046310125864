@import "./variables";

.dropdown-container {
    margin-bottom: 2em;
    position: relative;
    width: 100%;
    z-index: 100;

    .label {
        background-color: $white;
        padding: 0;
        margin: 10px 0;
        font-size: $font-size-label;
        font-weight: 500;
        color: $black;
    }

    .label.floating {
        font-family: $font-family !important;
        top: -0.5em;
        left: 2em;
        padding: 0 0.5em;
        z-index: 1;
    }

    &.filled {
        .ui.dropdown {
            border-color: $color-dark !important;
        }
    }

    .ui.dropdown {
        border-radius: 0;
        font-size: $font-size;
        padding-right: 0.5em;
        position: relative;
        min-height: 3.5em;
        display: flex;
        align-items: center;
        border-color: $color-gray;

        &.visible {
            z-index: 1;
        }

        .icon.chevron {
            position: absolute;
            right: 10px;
            top: 15px;
        }

        .default {
            color: $black !important;
            opacity: 0.5;
        }

        .label {
            background: $color-light;
            font-weight: bold;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .delete {
                color: $red;
            }
        }
    }
}

.dropdown-container.no-margin {
    width: 50%;
    margin: 0 0 0 $padding;
}

.dropdown-container.with-z-index-99 {
    z-index: 99 !important;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .dropdown-container {
        .ui.dropdown {
            flex-direction: column;
            align-items: flex-start;
            justify-content: center;

            .label {
                width: 70%;
            }
        }
    }

    .dropdown-container.no-margin {
        margin: $padding 0;
        width: 100%;
    }
}