@import './variables';


[class*='grid'],
[class*='col-'],
[class*='mobile-'],
.grid:after {
	-webkit-box-sizing: border-box;
	-moz-box-sizing: border-box;
	box-sizing: border-box;	
}

.grid {
	display: flex;
	flex-direction: row;
	width: 100%;
	min-width: 0;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1em;
	justify-content: center;

	&.small-grid{
		max-width: 720px;
	}
}



.grid-full {
	display: flex;
	max-width: 100% !important;
	flex-direction: row;
	justify-content: center;
	margin-left: auto;
	margin-right: auto;
	padding: 0 1em;
}

.grid:after, .grid-full:after, .grid-small:after {
	content: "";
	display: table;
	clear: both;
}


/* Content Columns */

[class*='col-'] {
	min-height: 1px;
	margin: .5em 0;
}

.col-100 {
	flex-basis: 100%;
    max-width: 100%;
}

.col-90 {
	flex-basis: 90%;
    max-width: 90%;
}

.col-60 {
	flex-basis: 66%;
    max-width: 66%;
}

.col-66 {
	flex-basis: 66.66%;
    max-width: 66.66%;
}

.col-50 {
	flex-basis: 50%;
    max-width: 50%;
}

.col-40 {
	flex-basis: 40%;
    max-width: 40%;
}

.col-35 {
	flex-basis: 35%;
    max-width: 35%;
}

.col-30 {
	flex-basis: 30%;
    max-width: 30%;
}


.col-33{
	flex-basis: 33.33%;
    max-width: 33.33%;
}

.col-25 {
	flex-basis: 25%;
    max-width: 25%;
}

.col-20 {
	flex-basis: 20%;
    max-width: 20%;
}

.col-16 {
	flex-basis: 16%;
    max-width: 16%;
}

.col-15 {
	flex-basis: 15%;
    max-width: 15%;
}

.col-14 {
	flex-basis: 14%;
    max-width: 14%;
}

.col-12 {
	flex-basis: 12%;
    max-width: 12%;
}

.col-11 {
	flex-basis: 11%;
    max-width: 11%;
}

.col-10 {
	flex-basis: 10%;
    max-width: 10%;
}

.col-9 {
	flex-basis: 9%;
    max-width: 9%;

}

.col-8 {
	flex-basis: 8%;
    max-width: 8%;
}

.stack {
	flex-direction: column;
	> [class*='col-'] {
		flex-basis: 100%;
		max-width: 100%;
		min-height: 1px;
		margin: .5em 0;
	}
}

.stack-desktop {
	flex-direction: column !important;

}
@media screen and (min-width: #{$grid-medium-breakpoint}){
	.grid {
		flex-direction: row;
		max-width: 1140px;
		min-width: 748px;
		padding: 0;
	}
	
	.stack {
		flex-direction: row;
	}

}
