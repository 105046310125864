@import "./src/scss/components/core";
@import "./src/scss/components/variables";
@import "./src/scss/components/utilities";
@import "./src/scss/components/layout";

.input-container {
    .form-header {
        color: $color-dark;
        font-family: "Nunito Sans";
        font-size: 1.25em;
        font-weight: bold;
    }

    .multi-input-line {
        margin-bottom: $padding;
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .input-half {
            width: 48%;
        }

        .input-longer {
            width: 56%;
        }

        .input-shorter {
            width: 40%;
        }
    }

    .successful-note {
        font-size: 1.4em;
        text-align: center;
    }
}

.input-container-half {
    .multi-input-line {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;

        .input-half {
            width: 48%;
        }

        .input-longer {
            width: 56%;
        }

        .input-shorter {
            width: 40%;
        }
    }
}

.cards-container {
    width: 100%;
}

.checkbox-container {
    margin: 2em 0;
    text-align: center;
}

.terms-links-container {
    text-align: left;
    font-size: 1em;
    margin: 1.2em 0;

    a {
        color: $color-dark !important;
        text-decoration: underline;

        &:hover {
            color: $color-cards !important;
        }
    }
}

.order-summary-container {
    padding: 0 2em 2em 2em;
    background-color: $color-extra-light-gray;

    .summary-item {
        padding: $padding 0;
        display: flex;
        justify-content: space-between;
        align-items: center;

        p {
            font-size: 1.5em;
            margin: 0;
            color: $black;
        }

        a {
            font-size: 1.5em;
            margin: 0;
            color: $black;
        }

        .bold {
            font-weight: bold;
        }

        .total {
            font-size: 1.6em;
        }

        span {
            font-size: 1.5em;
            color: $black;
        }
    }

    .promo-input {
        padding: 0 6em;
    }
}

.icons-section {
    padding: $padding;
    min-height: 10em;
    background-color: $white;
    display: flex;
    justify-content: space-between;
    align-items: center;

    .fixed-image {
        width: 120px !important;
    }
}

.ui.container.payment-container {
    .ui.grid.payment-grid {
        margin-left: 0;
        margin-right: 0;

        .row > .column > .step-six-form {
            padding: 0;

            .ui.accordion {
                .title {
                    padding: 0;
                    border-radius: 0px;
                    border-bottom-left-radius: 0;
                    border-bottom-right-radius: 0;

                    i {
                        display: none;
                    }

                    .ui.header {
                        margin: 0;
                        padding: $padding 0;
                        color: $color-cta;
                    }

                    &.active {
                        .ui.header {
                            color: $black;
                        }
                        border-bottom: none;
                    }
                }

                .content {
                    padding: 0 2em;
                    border-radius: 0px;
                    border-top-left-radius: 0;
                    border-top-right-radius: 0;
                    &.active {
                        border-top: none;
                    }
                }
            }
        }
    }
}

.show-mobile {
    display: none;
}

.show-computer {
    display: flex;
    flex-direction: column;
}

.input-errors {
    text-align: center;
    color: $red;
    margin-bottom: 1em !important;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .show-mobile {
        display: flex;
        flex-direction: column;
    }

    .show-computer {
        display: none;
    }

    .subscribe-text {
        padding: 0 5px;
    }

    .checkbox-container {
        text-align: left;
    }

    .terms-links-container {
        font-size: 0.8em;
    }

    .order-summary-container {
        margin-top: 2em;
        padding: 0 1em;
    }

    .ui.container.payment-container {
        margin: 0 !important;

        .ui.grid.payment-grid {
            .column {
                padding: 0;
            }

            .row > .column > .step-six-form {
                .ui.accordion {
                    .content {
                        padding: 0 1em;
                    }
                }
            }
        }
    }

    .input-container {
        padding: 0;

        .successful-note {
            font-size: 1.2em;
        }
    }

    .icons-section {
        .ui.image {
            width: 115px;
        }
    }
}

.hand-address-link {
    color: $red !important;
    outline: none;
    text-decoration: underline;
    cursor: pointer;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .cards-container {
        padding-left: $padding;
        padding-right: $padding;
    }
}

@media only screen and (max-width: 320px) and (orientation: portrait) {
    .icons-section {
        .ui.image {
            width: 90px;
        }
    }
}
