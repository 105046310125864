@font-face {
    font-family: "Nunito Sans";
    src: local("Nunito Sans"), url("../../fonts//NunitoSans-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Source Sans Pro";
    src: local("Source Sans Pro"), url("../../fonts/SourceSansPro-Regular.ttf") format("truetype");
    font-weight: normal;
}

@font-face {
    font-family: "Playfair Display";
    src: local("Playfair Display"), url("../../fonts/PlayfairDisplay-Bold.ttf") format("truetype");
    font-weight: 700;
}

// colors
$black: #000;
$white: #fff;

$color-dark: #444242;
$color-gray: #bdbcbc;
$color-light-gray: #d8d8d8;
$color-lght-gray-box: #d3d2d2;
$color-extra-light-gray: #f3f3f3;
$color-light: #f9ddb6;
$color-green-dark: #3f5e2b;

$color-cta: #ad7733;
$color-cta-light: #cd9b5b;
$color-orange-light: #f9eae3;

$green: #50d214;
$green-light: #dcf6d0;
$green-dark: #205408;
$green-ultra-light: #edfdff;

$red: #d2142d;
$red-light: #f7cecc;
$red-dark: #540820;

$color-orange-dark: #922600;
$orange: #ed9220;
$orange-light: #f6dcd0;
$orange-dark: #542008;

$color-yellow: #ffd55a;

$color-purple: #eddaff;

$color-title: #c19056;
$color-cards: #b98b55;

// fonts
$font-size: 17px;
$font-family: "Nunito Sans", sans-serif;
$font-small: 0.9em;
$font-line-height: 1.5;
$font-weight-regular: 400;
$font-weight-medium: 500;
$font-size-label: 17px;
$font-small: 0.9em;
$font-small-header: 1.2em;
$font-line-height: 1.5;

// font for title
$title-font-family: "Playfair Display", serif;
$subtitle-font-family-regular: "Source Sans Pro", sans-serif;

// box size
$margin: 1em;
$margin-large: 1.5em;
$padding: 1em;
$padding-large: 1.5em;
$border-radius: 0.2em;

$grid-medium-breakpoint: 820px !default; // Medium screen breakpoint for grid.
$grid-large-breakpoint: 1280px !default; // Large screen breakpoint for grid.

//paddings
$padding-top: 2em;
$lesser-padding-top: 0.9em;
$padding-left: 2.5em;
$padding-bottom: 2em;
$padding-right: 1em;
$short-padding-left: 1.5em;
