@import "./variables";
@import "./utilities";

.sticky-top {
    position: sticky;
    background-color: $white;
    top: 0;
    min-width: 467px;
    left: 0;
    right: 0;
    z-index: 10;
    box-shadow: -2px -2px 5px 1px $color-light-gray;

    .navbar {
        height: 5em;
        padding: 0 10% !important;
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;

        .logo {
            padding-top: 1.6rem;
            padding-left: 0.2rem;
            padding-bottom: 0.3125rem;
        }

        .route-container {
            width: 75%;
            display: flex;
            align-items: center;
            justify-content: flex-end;

            .step-information {
                margin: 0;
                font-size: $font-size;
                margin-right: $margin;
            }

            .help-chat {
                background: transparent;
                border: none;
                outline: none;
                cursor: pointer;
                color: $color-dark;
                font-weight: 700;
                font-size: 16px;
            }

            .help-chat:hover {
                text-decoration: none;
            }

            .support {
                background: url("../../images/support-dark.svg") !important;
                background-color: $color-cards;
                background-position: center left !important;
                background-size: 18px !important;
                background-repeat: no-repeat !important;
                padding-left: 26px !important;

                &:hover {
                    color: $color-cards;
                }
            }
        }

        .log-out {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;
            padding: 0 0 0 1em;

            .logout-button {
                background: url("../../images/manage-icon.svg") !important;
                background-color: $color-dark;
                background-position: center left !important;
                background-size: 18px !important;
                background-repeat: no-repeat !important;
                padding-left: 26px !important;
                border: none;
                outline: none;
                cursor: pointer;
                font-weight: 700;
                font-size: 16px;
            }
            .logout-button:hover {
                background: url("../../images/manage_icon_active.svg") !important;
                background-color: $color-cards;
                color: $color-cards;
                background-repeat: no-repeat !important;
                background-size: 18px !important;
                padding-left: 26px !important;
                background-position: center left !important;
                border: none;
                outline: none;
                font-size: 16px;
            }
            .active {
                background: url("../../images/manage_icon_active.svg") !important;
                background-color: $color-cards;
                color: $color-cards;
                background-repeat: no-repeat !important;
                background-size: 18px !important;
                padding-left: 26px !important;
                background-position: center left !important;
                border: none;
                outline: none;
                font-weight: 700;
                font-size: 16px;
            }
        }
    }
}

.ui.vertical.menu {
    width: 10em !important;
    position: absolute;
    right: 10%;
}

@media only screen and (max-width: 719px) {
    .sticky-top {
        z-index: 2000;

        img {
            width: 55px !important;
        }

        .navbar {
            padding: 0 0.5em !important;
            height: 66px;
            z-index: 10;

            .logo {
                padding-top: 0;
                padding-left: 0;
                padding-bottom: 0;
            }
        }
    }

    .ui.vertical.menu {
        right: 6%;
    }
}

@media only screen and (width: 768px) and (height: 1024px) {
    .sticky-top {
        img {
            width: 55px !important;
        }
    }
}
