@import "./variables";

.sub-header-container {
    padding: 0;
    min-height: 5em;

    &.sub-header-padding-small {
        .sub-header {
            padding: 0;
            display: flex;
            justify-content: flex-start;
            align-items: flex-start;

            .subscribe-title {
                margin: 0.5em 0 0.5em 0.7em !important;
            }
        }
    }

    .sub-header {
        padding: 0;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        position: relative;

        &.text-left {
            align-items: flex-start !important;

            .subscribe-title {
                margin: 0 !important;
                font-size: 3em;
            }
        }

        .gift-name-title {
            margin: 0 0 2em 0;
        }

        .user-info {
            width: 100%;
            text-align: right;
            font-size: $font-small;

            .ui.button {
                background-color: $white;

                .icon {
                    background-color: $white;
                }
            }
        }

        .ui.button.back-arrow {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            left: -0.5em;
            width: 2em;
            height: 2em;
            top: 2em;
            padding: 0;
            margin: 0;
        }

        .ui.button.back-close {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            right: 0;
            width: 2em;
            height: 2em;
            top: 2em;
            padding: 0;
            margin: 0;
        }

        .ui.header.subscribe-title {
            font-size: 3em;
            font-weight: bold;
            font-family: $title-font-family !important;
            color: $color-title;
            width: max-content;
            margin: $margin 0 0.2em 0;
        }

        .ui.header.box-title {
            font-size: 1.6em;
            font-weight: bold;
        }

        .subscribe-text {
            text-align: center;
            padding-bottom: 0;
            margin-top: 0.5em;
            font-size: 1.125em;
            font-family: $font-family !important;
            margin: 0;

            &.lesser {
                color: $color-gray !important;
            }

            &.secondary-text {
                color: $color-dark;
                font-size: 1em;
                margin: 0 0 2em 0;
            }
        }

        .subscribe-text.box-title {
            color: $color-cta;
            text-transform: uppercase;
            font-weight: bold;
        }
    }

    .ui.divider {
        margin-top: 0;
        margin-bottom: 2em;
    }

    .ui.header.subtitle {
        font-size: 1.43em;
        margin: 1.5em 0 1.5em 0;
        font-family: $font-family !important;
        font-weight: 700;
    }
}

.ui.subtitle {
    font-size: 1.43em;
    margin: 0.5em 0 0.5em 0;
    font-family: $font-family !important;
    font-weight: 700;
    &.bigger {
        font-size: 1.715em !important;
    }
}

.align-left {
    justify-content: left !important;
    align-items: flex-start !important;
}

.sub-header-container.short-padding {
    padding: 3em 0;
}

.box-sub-header-container {
    .sub-header {
        padding: 2em 0 0 0;
        position: relative;

        .header {
            color: $color-cards;
            font-size: 3em;
        }

        .box-subtitle {
            text-align: center;
            font-size: 1.5em;
        }

        .ui.button.back-close {
            position: absolute;
            background: transparent;
            border: none;
            outline: none;
            right: -3em;
            width: 2em;
            height: 2em;
            top: 1.5em;
            padding: 0;
            margin: 0;
        }
    }
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .sub-header-container,
    .sub-header-container.short-padding {
        padding: 0;
    }

    .sub-header-container {
        .sub-header {
            .ui.header.subscribe-title {
                font-size: 2em;
                margin-left: 0 !important;
            }
        }
    }

    .box-sub-header-container {
        .sub-header {
            .ui.button.back-close {
                right: -0.5em;
                top: 3em;
            }
        }
    }
}

.subheader-simple {
    font-family: $font-family !important;
    font-size: 18px;
    &.lesser {
        font-family: $font-family !important;
        font-size: 16px !important;
        color: $color-dark !important;
        font-weight: 300;
    }
}

@media only screen and (max-width: 550px) {
    .ui.header.subscribe-title {
        font-size: 2em !important;
    }
}