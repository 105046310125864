@import "../components/variables";
@import "../components/containers";

body #root {
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-family: $font-family !important;
}

section,
div,
h1,
h2,
h3,
h4,
h5,
h6,
p,
input,
label,
button,
a {
    font-family: $font-family !important;
    color: $color-dark;
}

input[type="number"] {
    -moz-appearance: textfield;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}
