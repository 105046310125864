@import "_variables";

.color-yellow {
    color: $color-yellow;
}

.color-orange {
    color: $orange;
}

.color-gray {
    color: $color-gray !important;
}

.color-white {
    color: $white;

    .a {
        color: $white !important;
    }

    a:link {
        color: $white !important;
    }

    a:visited {
        color: $white !important;
    }
    a:hover,
    a:focus {
        color: $color-light-gray !important;
    }
}

.color-cta {
    color: $color-cta !important;
}

.color-cta-light {
    color: $color-cta-light;
}

.color-black {
    color: $black !important;
}

.color-red {
    color: $red !important;
}

.color-white {
    color: $white !important;
}

.color-light-gray {
    color: $color-light-gray;
}

// backgrounds
.bg-yellow-light {
    background-color: $color-light;
}

.bg-orange {
    background-color: $orange !important;
}

.bg-orange-light {
    padding-top: $padding-top;
    background-color: $color-orange-light;
}

.bg-orange-dark {
    background-color: $color-orange-dark;
}

.bg-white {
    background-color: $white;
}

.bg-gray {
    background-color: $color-gray;
}

.bg-light-gray {
    background-color: $color-light-gray;
}

.bg-dark {
    background-color: $color-dark;
}

.bg-green-dark {
    background-color: $color-green-dark;
}

.bg-red-light {
    background-color: $red-light;
}

.bg-green-ultralight {
    background-color: $green-ultra-light;
}

.bg-cta {
    background-color: $color-cta;
}

.bg-extra-gray {
    background-color: $color-extra-light-gray;
}

// .maw
.margin-bottom {
    margin-bottom: 1em !important;
}

.margin-right {
    margin-right: $margin-large !important;
}

.margin-left {
    margin-left: $margin-large !important;
}

.margin-top {
    margin-top: 3em;
}
.margin-top-0 {
    margin-top: 0 !important;
}
.margin-bottom-0 {
    margin-bottom: 0 !important;
}
.margin-bottom-lesser {
    margin-bottom: 0.1em !important;
}

.margin-center {
    margin-left: auto;
    margin-right: auto;
}

.padding {
    padding: 0 0.5em 0 1em;
}

.padding-0 {
    padding: 0;
}

.padding-text {
    padding-left: 1em;
    padding-right: $padding-right;
}

.padding-bottom {
    padding-bottom: $padding-bottom;
}

.top-line {
    padding-top: $padding-top;
    border-top: 1px solid $color-light-gray;
    font-size: 0.9em;
}

.image-left {
    padding-top: $padding-top;
    padding-left: $padding-left;
}

.image-right {
    padding-top: $padding-top;
    padding-right: $padding-right;
}

.top-padding {
    padding-top: $padding-left;
}

.lesser-padding-top {
    padding-top: $lesser-padding-top !important;
}

.left-padding {
    padding-left: $padding-left;
}

.short-padding-left {
    padding-left: $short-padding-left;
}

.lesser-margin {
    margin-top: 0em !important;
    margin-bottom: 1.5em;
}

.lesser-padding {
    padding-left: 1.786em;
    padding-right: 1.786em;
}

.align-center {
    align-items: center;
}

.hide-mobile-inline {
    display: none;
}

.hide-mobile {
    display: none !important;
}

.hide-desktop {
    display: block !important;
}

.hide-desktop-inline {
    display: inline-block !important;
}
//grid-content

.grid-justify-left {
    justify-content: flex-start;
}

.center-on-grid {
    align-self: center;
}

// images
.center-img {
    display: block;
    margin-left: $margin-large;
    margin-right: $margin-large;
    padding-bottom: $padding-bottom;
}

//buttons
.btn-sm {
    padding: 10px;
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    border: 0.063em solid $color-cta-light;
    background-color: $color-orange-light;
    color: $color-cta-light;
    text-decoration: none;

    &:hover {
        cursor: pointer;
        border: 0.063em solid $color-orange-light;
        background-color: #fcf2ed;
        text-decoration: none;
    }
}

.button {
    border-radius: 0.3em;
    font-weight: $font-weight-medium;
    text-align: center;
    display: inline-block;
    margin: $margin 0;
    border: 2px solid $color-cta;
    background-color: $color-cta;
    color: $white;
    padding: 10px 80px;
    width: 14em;
    text-decoration: none;
    align-items: center;
    cursor: pointer;
}

.text-bold {
    font-weight: 600;
}

.text-center {
    text-align: center !important;
}

.text-left {
    text-align: left !important;
}

.text-h1 {
    font-size: 1.715em !important;
    font-weight: 700;
    font-family: $font-family !important;
    margin-bottom: 0.1em !important;
}

.flex-center {
    display: flex;
    align-items: center;
    justify-content: center;
}

.flex-column {
    flex-direction: column;
}

.floated-button-small {
    position: absolute;
    top: -10px;
    right: -10px;
    font-weight: bold;
    color: $white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    height: 2em;
    width: 2em;
    z-index: 2;
    padding: 0.12em;
}

.floated-button-small:focus {
    outline: none;
}

.floated-button-large {
    position: absolute;
    top: -1.5em;
    right: -1.5em;
    background: $color-cta;
    font-weight: bold;
    color: $white;
    border-radius: 50%;
    border: none;
    cursor: pointer;
    height: 3em;
    width: 3em;
    z-index: 2;
    padding: 0.3em;
}

.floated-button-large:focus {
    outline: none;
}

.icon-button {
    border: none;
    outline: none;
    border-radius: 10px;
    padding: 10px;
    width: 8em;
}

.detault-paragraph {
    font-size: 1.15em;
}

@media only screen and (max-width: 768px) and (orientation: portrait) {
    .floated-button-large {
        top: -1em;
        right: -1em;
        height: 2.2em;
        width: 2.2em;
        padding: 0.18em;

        i.icon {
            font-size: 1.6em;
        }
    }
}

//breakpoints
@media screen and (min-width: #{$grid-medium-breakpoint}) {
    .hide-mobile-inline {
        display: inline-block;
    }

    .hide-mobile {
        display: block !important;
    }

    .hide-desktop {
        display: none !important;
    }

    .margin-right-desktop {
        margin-right: $margin-large !important;
    }

    .margin-left-desktop {
        margin-left: $margin-large !important;
    }
}

.container-center {
    margin-top: 4em;
    padding-top: 4em;
}

.clickable-icon {
    cursor: pointer;
}

.underlined-link {
    text-decoration: underline;
}

.custom-padding-top {
    padding-top: 0.7rem;
}

.custom-margin-bottom {
    margin-bottom: 2.3rem !important;
}
