@import "./variables";

.ui.button.default-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    background-color: $color-cards;
    color: $white;
    padding: 13px;
    border-radius: 0px !important;
    height: 3.5em;
    text-transform: uppercase;

    &.cancel {
        margin: 5px;
        padding: 5px;
        background-color: #f7efe0;
        color: $color-cards;
        border: 1px solid $color-cards;
        height: 2em;
    }

    &.accept {
        background-color: $color-cards;
        height: 2em;
    }

    &:hover {
        transform: scale(1.05);
    }

    &.inverted {
        background-color: transparent;
        border: 1px solid $color-cards;
        color: $color-cards;

        &:hover {
            background-color: $color-cards;
            color: $white;
        }
    }

    &.hidden {
        visibility: hidden !important;
    }
}

.ui.button.social-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    display: inline-block;
    color: $white;
    padding: 13px;
    background-color: #3b5998;
    border-radius: 0px;

    &.google {
        background-color: #db4a39;
        margin-right: 30px;
    }
}

.ui.button.default-button.disabled {
    background-color: $color-light-gray;
    color: $white;
}

.ui.button.outline-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    background-color: transparent;
    color: $color-light-gray;
    padding: 13px;
    margin: 10px 5px;
}

.ui.button.outline-button:hover {
    color: $color-gray;
}

.ui.button.back-button {
    font-family: $font-family;
    font-weight: $font-weight-medium;
    font-size: 16px;
    border-radius: 0.5em;
    display: inline-block;
    background-color: transparent;
    color: $color-dark;
    padding: 13px;
    margin: 5px;
    text-decoration: underline;
}

.ui.button.back-button:hover {
    color: $color-cards;
}

.ui.button.default-button.dark {
    background-color: #333333;
}
.ui.button.default-button.dark:hover {
    background-color: $color-gray;
}

.ui.button.default-button.inverted:hover {
    transform: scale(1.05);
}

.link-container-padding {
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        padding-top: 0.1em;
        margin: 0;
    }

    .ui.button.link-button {
        background-color: transparent;
        border: none;
        text-decoration: underline;
        padding: 0;
        margin: 0.4em;
        font-size: 1.25em;
        color: $color-cta;
        width: auto;

        &.dark {
            color: $color-dark;
            font-weight: 400;
        }

        &.text-small {
            font-size: 1.3em !important;
        }
    }

    .ui.button.link-button:hover {
        color: $color-cards;
    }
}

.link-container-padding.flex-start {
    justify-content: left;
    .ui.button.link-button {
        color: $color-dark;
    }
}

.width-fixed {
    width: 26.69em !important;
    height: 3.75em !important;
}

.width-ten-percent {
    width: 100% !important;
    height: 3.75em !important;
}

@media only screen and (max-width: 742px) and (orientation: portrait) {
    .ui.button {
        font-size: 12px !important;
    }
}
@media only screen and (max-width: 1024px) and (orientation: portrait) {
    .width-fixed {
        width: 20em !important;
        height: 3.75em !important;
    }
}
